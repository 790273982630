<template>
  <v-row align="center">
    <v-spacer />
    <v-col :cols="$vuetify.breakpoint.mobile ? 9 : 4">
      <v-card flat elevation="2" outlined color="var(--color-card)">
        <v-card-title class="text-xl-h4 white--text text-capitalize my-2">{{$t(`message.${title}`)}}</v-card-title>
        <v-card-text class="white--text">
          <slot></slot>
        </v-card-text>
      </v-card>
    </v-col>
    <v-spacer />
  </v-row>
</template>

<script>
export default {
  name: "Card",
  props: {
    title: String,
  },
};
</script>